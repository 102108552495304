import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";

import OfferForm from "../components/OfferForm";
const img = require('../../assets/images/slider/v7_07.jpg')

const P = styled.p`
font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`


const Pfx = styled.p`
font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 1em;
line-height: 1.5em;`


const Flex = styled.div`
width:100%;
display:flex;
justify-content:space-between;


 @media screen and (max-width: 870px) {
 display:block;
 }
`

const Img = styled.img`

height:805.98px;
margin-left:24px;


 @media screen and (max-width: 870px) {
 margin-top:6em;
width:100%;
height:auto;
margin-left:0px;
 }
`

class Kontakt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <Element id={'header'}/>
        <br/>

        <h1>Kontakt</h1>
        <br/>
        <Pfx>

          Vilhelm7 Boutique Coworking <br/>
          Wilhelmstraße 7 <br/>
          10963 Berlin



        </Pfx>

        <a href={'mailto:info@vilhelm7coworking.de'}>info@vilhelm7coworking.de</a> <br/>
        <a href={'tel:+491732070173'}>+49 173 2070 173</a>
        <br/>
        <br/><br/>
        <P><b>Vilhelm7 Boutique Coworking: </b>Unverwechselbare Arbeitsbereiche, in denen sich das Arbeiten angenehm und hocheffizient gestalten lässt. Hochmoderne Räume in einer ruhigen Arbeitsumgebung machen unsere Private Offices im Hochparterre unseres renovierten Altbaus einzigartig.
          Eine dezente, stilvolle Einrichtung und ein schönes Ambiente sorgen für eine Wohlfühlatmosphäre in unseren Private Offices.</P>

<h2>Starten Sie Ihre Anfrage</h2>
        <br/>
        <br/>
        <Flex>
          <OfferForm/>
          <Img src={img}></Img>
        </Flex>
      </Container>
    </Page>
  </IndexLayout>
    )
  }
}

export default Kontakt
